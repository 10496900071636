<template>
  <div class="">
    <ExternalEventForm ref="form" action="Update" v-model="formData" @formSubmitted="updateExternalEvent" />
  </div>
</template>
  
  <script>
import ExternalEventForm from "@/views/components/external-event/ExternalEventForm";
import ExternalEventService from "@/services/ExternalEventService";

export default {
  name: "UpdateExternalEvent",
  components: {
    ExternalEventForm
  },
  data() {
    return {
      externalEventService: new ExternalEventService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateExternalEvent(e) {
      this.$refs.form.loading = true;
      this.externalEventService.update(this.formData._id, e).then(res => {
        this.$router.push('/external-event')
      }).catch(err => {
        console.log(err);
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getExternalEvent() {
      this.externalEventService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
        this.formData.topics = this.formData.topics.map(e => e._id);
        this.formData.languages = this.formData.languages.map(e => e._id);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getExternalEvent()
  }
}
</script>
  
  <style scoped>
  </style>
  